<template>
    <div class="guarantee">
        <div class="content">
            <!-- 顶部 -->
            <Header :borderShow="false" titleName="保障业务" style="display: none;" />
            <!-- 轮播 -->
            <van-swipe :autoplay="3000" :loop="true" @change="onChange2">
                <van-swipe-item v-for="(item, index) in data.swiper" :key="index" @click="toBannerDetails(item.linkUrl)">
					<img v-if="item.filePath" :src="item.filePath" alt="">
					<img class="empty_img" v-else src="@/assets/images/empty_img.png" alt="">
				</van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                        <div class="box" v-for="(item, index) in data.swiper" :key="index"
                            :class="index == data.current ? 'active' : ''">
                        </div>
                    </div>
                </template>
            </van-swipe>
            <div class="search_box">
                <div class=" search_input space_between align_items_center">
                    <div class="align_items_center" style="padding-left: .4rem;">
                        地名
                        <img style="width: .2rem;margin: 0 .2rem;" src="@/assets/images/guarantee/bottom.png" alt="">
                        <div class="align_items_center">
                            <van-field v-model="data.adr" placeholder="请输入前往的地名" @keyup.enter="submit()"
                                ref="inputRef" />
                        </div>
                    </div>

                    <div class="serach_btn" @click="submit()" :return-key-type="data.returnKeyType">前往</div>
                </div>

            </div>

            <div class="list_view">
                <div class="list_view_title">
                    <img class="btn_title_bg" src="@/assets/images/guarantee/btn_title_bg.png" alt="">
                    <!-- <div class="text"> <span class="cred">中国职工</span>保险互助专区</div> -->
                    <div class="text" style="padding-top: .1rem;">
                        <img class="logo_img" src="@/assets/images/logo.png" alt="">
                    </div>
                </div>
                <div class="list">
                    <div v-for="(item, index) in data.chinaList" :key="index" :class="item.linkUrl ? '' : 'noActive'"
                        @click="todetails(item)">
                        <img :src="item.imgUrl" alt="">
                        <div>{{ filterName('name', item.title) }}</div>
                        <div style="display: none;" class="tag">{{ filterName('tag', item.title) }}</div>
                    </div>
                </div>
            </div>

            <div class="list_view">
                <div class="list_view_title">
                    <img class="btn_title_bg" src="@/assets/images/guarantee/btn_title_bg2.png" alt="">
                    <div class="text" style="padding-top: .2rem;"> <span class="cred"
                            style="font-weight: 800;">地方机构</span></div>

                </div>
                <div class="list">
                    <div v-for="(item, index) in data.cityList" :key="index" :class="item.linkUrl ? '' : 'noActive'"
                        @click="todetails(item)">
                        <img :src="item.imgUrl" alt="">
                        <div>{{ filterName('name', item.title) }}</div>
                        <div class="tag">{{ filterName('tag', item.title) }}</div>
                    </div>
                </div>
            </div>
        </div>


        <div class="jj_dialog" v-if="data.selectShow" @click="data.selectShow = false">
            <div class="select_view" @click.stop="">
                <div v-if="data.selectedChinaList.length > 0">
                    <div class="title"><span class="cred">中国职工</span>保险互助专区</div>
                    <div class="item ">
                        <div class="space_between align_items_center" v-for="(item, index) in data.selectedChinaList"
                            :key="index" @click="todetails(item)">
                            <div class="align_items_center">
                                <img class="av_img" :class="item.linkUrl ? '' : 'noActive'" :src="item.imgUrl" alt="">
                                {{ item.title }}
                            </div>
                            <div class="btn" :class="item.linkUrl ? '' : 'noActive'">立即进入</div>
                        </div>
                    </div>
                </div>
                <div v-if="data.selectedCityList.length > 0">
                    <div class="title"><span class="cred">地方互助</span>保障组织专区</div>
                    <div class="item">
                        <div class="space_between align_items_center" v-for="(item, index) in data.selectedCityList"
                            :key="index" @click="todetails(item)">
                            <div class="align_items_center">
                                <img class="av_img" :class="item.linkUrl ? '' : 'noActive'" :src="item.imgUrl" alt="">
                                {{ item.title }}
                            </div>
                            <div class="btn" :class="item.linkUrl ? '' : 'noActive'">立即进入</div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-if="data.selectedChinaList.length == 0 && data.selectedCityList.length == 0">
                    <img src="@/assets/images/guarantee/search_empty.png" alt="">
                    <div>暂未开通 敬请期待...</div>
                </div>
                <div class="bigBtn" @click="data.selectShow = false">关闭</div>
            </div>

        </div>
        <Footer />
    </div>
</template>

<script setup>
import Footer from "../../components/Footer.vue";
import { reactive, toRefs, ref, onMounted, computed } from "vue";
import { jsToCallApp } from '../../common/js/callApp1.0.0'
import Header from "../../components/Header.vue";
import { useRoute, useRouter } from 'vue-router'
import { Toast, Popup } from 'vant';
import Api from "../../utils/api";
const route = useRoute()
const router = useRouter()
const inputRef = ref(null);
let data = reactive({
    adr: '',
    selectShow: false,
    chinaList: [],
    cityList: [],
    selectedChinaList: [],
    selectedCityList: [],
    bannerObj: {},
    returnKeyType: 'send',
    swiper: [],
    current: 0,
});

onMounted(() => {
    getAllList()
    getCityList()
    getBanner()

    const jsonString = {
        shareUrl: 'https://quanzong.cwmia.com/#/guarantee',//分享链接
        shareCorver: 'https://quanzong.cwmia.com/logo.png',
        shareTitle: '中国职工保障互助会',
        shareDesc: '保障业务',//分享描述
    }
    console.log(jsonString)
    openAppFunction('setupShareData', { shareData: JSON.stringify(jsonString) })
});

function openAppFunction(type, jsonData) {
    const useTimeOut = setTimeout(() => {
        jsToCallApp(
            {
                type: type,
                ...jsonData,
            },
            (data) => {
                console.log(type, data)
            },
        )
        clearTimeout(useTimeOut);
    }, 300);
}
// 获取轮播banner 会员服务
const getBanner = () => {
    Api.getBanner({ name: 'GuaranteeService' }).then(res => {
        if (res.status == 200) {
            data.swiper = res.data.banner
        }
    })
}

//前往
const submit = () => {

    if (!data.adr) {
        Toast('请输入地名！');
        return
    }
    data.selectedChinaList = []
    data.selectedCityList = []
    data.selectShow = true
    data.selectedChinaList = keySearch(data.chinaList)
    data.selectedCityList = keySearch(data.cityList)
    inputRef.value.blur()

}

//关键字搜索
const keySearch = (array) => {
    let newList = array.filter(item => {
        return item.title.indexOf(data.adr) !== -1 && item.linkUrl
    })
    return newList
}
//跳转到详情
const todetails = (item) => {
    if (item.linkUrl) {
        window.location.href = item.linkUrl
        // console.log(111)
    }

}

// 中国职工保险互助专区列表
const getCityList = () => {
    Api.articleViewChannelService({ categoryId: 16 }).then(res => {
        if (res.status == 200) {
            data.cityList = dataFilter(res.data)
        }
    })
}

// 中国职工保险互助专区列表
const getAllList = () => {
    Api.articleViewChannelService({ categoryId: 15 }).then(res => {
        if (res.status == 200) {
            data.chinaList = dataFilter(res.data)
        }
    })
}
//排序
const dataFilter = (array) => {
    let activeList = []
    let noActiveList = []
    for (let i = 0; i < array.length; i++) {
        if (array[i].linkUrl) {
            activeList.push(array[i])
        } else {
            noActiveList.push(array[i])
        }

    }
    return [...activeList, ...noActiveList]
}
const toBannerDetails = (url) => {

    if (url) {
        window.location.href = url
    }
};
//显示第一个字
const filterName = computed(() => (name, str) => {
    if (str.indexOf(':') !== -1) {
        if (name == 'name') {
            return str.split(':')[0]
        }
        if (name == 'tag') {
            return str.split(':')[1]
        }
    } else if (str.indexOf('：') !== -1) {

        if (name == 'name') {
            return str.split('：')[0]
        }
        if (name == 'tag') {
            return str.split('：')[1]
        }
    }
    else {
        if (name == 'name') {
            return str
        }
        if (name == 'tag') {
            return str.split('')[0]
        }
    }

})
const onChange2 = (index) => {
    data.current = index;
};
</script>

<style lang='less' scoped>
::v-deep input.van-field__control::-webkit-input-placeholder {
    color: #ACACAC;
    font-size: .3867rem;
}

.guarantee {
    display: flex;
    flex-flow: column;
    height: 100%;

    .van-swipe {
        height: 5.3333rem;
        position: relative;
        margin-bottom: .4rem;

        .van-swipe-item {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .empty_img {
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    .custom-indicator {

        position: absolute;
        bottom: .1333rem;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .box {
            width: .1067rem;
            height: .1067rem;
            border-radius: .1067rem;
            background-color: #fff;
            margin-right: 3px;
        }

        .active {
            width: .2533rem;
        }
    }

    .select_view {
        width: 100%;
        background: #FFFFFF;
        border-radius: .4rem .4rem 0px 0px;
        padding: .8rem .4rem;
        position: absolute;
        left: 0;
        bottom: 0;

        .title {
            font-size: .5rem;
            font-weight: 600;
            margin-bottom: .4rem;
        }

        .bigBtn {
            width: 100%;
            height: 1.1333rem;
            border-radius: .1067rem;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: #E61D18;
            font-size: .4rem;
            margin-top: 1.5rem;
            margin-bottom: .6667rem;
        }

        .empty {
            padding: .6667rem 0;
            font-size: .4rem;
            text-align: center;

            img {
                width: 3.1733rem;
            }
        }

        .item {
            width: 100%;
            max-height: 4rem;
            overflow-y: scroll;
            background: #F5F5F5;
            border-radius: .1333rem;
            margin-bottom: .8rem;
            font-size: .4rem;
            padding: 0 .4rem;

            >div {
                height: 1.4667rem;
            }

            .av_img {
                margin-right: .26rem;
                width: .9067rem;
                height: .9067rem;
                border-radius: .9067rem;
            }

            .btn {
                width: 2.0533rem;
                height: .72rem;
                background: #FFEFEF;
                border-radius: .1067rem;
                border: .0133rem solid #E61D18;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #E61D18;
                font-size: .3733rem;
            }
        }
    }

    .noActive {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
        opacity: 0.7; //通过改变透明度来调节灰色的程度
    }

    .content {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

        .search_box {
            width: 9.2267rem;
            height: 1.5733rem;
            background: #FFFFFF;
            border-radius: .1333rem;
            margin: 0 .4rem;
            padding: .2667rem;
            font-size: .3733rem;
            margin-bottom: .5067rem;
        }

        .search_input {
            border: .0133rem solid #ACACAC;
            height: .92rem;
            position: relative;
            border-radius: .1333rem;

            .van-cell {
                padding: 0;
                border: none;
            }

            .van-dropdown-menu {
                width: 2.5rem;

                box-shadow: none;

                background-color: rgba(0, 0, 0, 0);
            }

            .serach_btn {
                position: absolute;
                right: -0.0133rem;
                top: -0.0133rem;
                width: 1.5333rem;
                height: .93rem;
                background: #E61D18;
                border-radius: 0px .1333rem .1333rem 0px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .36rem;
            }
        }

        .banner {
            width: 100%;
            margin-bottom: .3333rem;
            height: 5.3333rem;
            text-align: center;
            background-color: rgba(240, 240, 240);

            .banner_img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .empty_img {
                width: 1.6rem;
                height: 1.6rem;
                margin-top: 2rem;
            }
        }
.list_view_btn{
	padding: .3rem;
	margin: 0.4rem;
	background-color: #fff;
	border-radius: .1333rem;
	margin-bottom: .5333rem;
	text-align: center;
	a:first-child{margin-right: 0.4rem;}
	a{background-color: rgba(255, 232, 230, 1);
    border-radius: 10px;
    padding: 0.4rem;
	width: 4rem;
	display: inline-flex;
}
	span{
		overflow-wrap: break-word;
		    color: rgba(255, 69, 69, 1);
		    font-size: .380rem;
		    letter-spacing: 0.01rem;
		    font-weight: bold;
		    text-align: left;
		    white-space: nowrap;
		    margin-left: 0.2rem;
			font-family: PingFangSC-Medium;
	}
	img{width: 0.5rem;height: 0.5rem;}
}


        .list_view {
            padding: 0 0 .6667rem 0;
            margin: 0 .4rem;
            background-color: #fff;
            border-radius: .1333rem;
            margin-bottom: .5333rem;

            .list {
                display: flex;
                flex-wrap: wrap;

                >div {
                    width: 1.1733rem;
                    font-size: .4rem;
                    width: 25%;
                    text-align: center;
                    margin-bottom: .4rem;
                    position: relative;

                    .tag {
                        width: .5067rem;
                        height: .5067rem;
                        border-radius: .5067rem;
                        border: .0533rem solid #FFFFFF;
                        position: absolute;
                        background-color: #FF6767;
                        font-size: .28rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        right: .5rem;
                        color: #fff;
                        top: 0;
                    }

                    img {
                        width: 1.1733rem;
                        height: 1.1733rem;
                        border-radius: 1.1733rem;
                        object-fit: cover;
                    }
                }


            }

            .list_view_title {
                width: 7.1467rem;
                height: 1.2rem;
                position: relative;
                margin: 0 auto;
                font-size: .5067rem;
                font-weight: 600;
                margin-bottom: .48rem;

                .btn_title_bg {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: -0.0667rem;
                }

                .text {
                    width: 100%;
                    height: 100%;
                    text-align: center;

                    position: relative;
                    z-index: 2;

                    .logo_img {
                        width: 70%;
                    }

                }
            }
        }


    }
}
</style>